import React from "react";

export default function ErrorMessage() {
  return (
    <div className="container">
      <div class="alert alert-danger react-alert-danger" role="alert">
        <p>Sorry, something went wrong!</p>
        <p>
          You can try to reload the page to fix this. Contact us at{" "}
          <a href="mailto:hi@asabove.us">hi@asabove.us</a> if the error
          persists. You can also try this page on a different device.
        </p>
      </div>
    </div>
  );
}
