import moment from 'moment';
import * as momentb from 'moment-business-days';

// also make this calc in shopify order confirm page
function swDateCountdown(){
  var today = moment();

  // Set the target date to December 20th of the current year
  var swTargetDate = moment("2023-12-18"); // Update the year as needed

  // Calculate the difference in weeks
  return swTargetDate.diff(today, 'weeks');
}

export function calcShipDates(shipTimes, items){

  const shipDates = []

  items.forEach(item=>{

    let itemTypeAttr = item.node.customAttributes.find(attr => attr.key === 'type');

    if( !itemTypeAttr ) return;

    let itemType = itemTypeAttr.value;

    let itemMaterialAttr = item.node.customAttributes.find(attr => attr.key === 'material');

    let itemMaterial = null; // this matches the JSON DB value for nothing set

    if( itemMaterialAttr ){
      itemMaterial = itemMaterialAttr.value;
    }

    if( itemMaterial || itemType === 'poster' ){

      const estimate = calcShipDate(shipTimes, itemType, itemMaterial);

      shipDates.push({
        type:itemType,
        time:`${estimate}-${estimate+1}`
      });

    }else{
      return;
    }
  });

  return shipDates;
};

export function calcShipDate(shipTimes, itemType, itemMaterial ){
  let time = shipTimes.find(time => time.material === itemMaterial && time.product_type === itemType);

  if( time && time.ship_time ){

    let businessDays = time.ship_time;

    if( itemType === 'pendant' ){
      // in ruby we set extra days here: 
      // https://github.com/awongh/jt/blob/master/app/models/piece.rb#L73
      let shipping = 5;
      let pickAndPack = 5;

      businessDays += shipping + pickAndPack;
    }

    const days = moment().businessAdd( businessDays );
    const now = moment();

    let waitInWeeks = days.weeks() - now.weeks();

    // account for when days.weeks() - the date in weeks
    // flips to the new year - weeks = 1
    // subtracting new year weeks - late in year weeks
    // will be negative
    if( waitInWeeks < 0 ){
      waitInWeeks += 52;
    }

    // only for xmas
    // uncomment this to count the date down
    /*
    if( itemType === 'pendant' || itemType === 'ring' ){
      waitInWeeks = swDateCountdown();
    }
    */

    return waitInWeeks;

  }else{
    // default to a nice UI that doesnt error
    return 1;
  }
}
