import React from 'react';

import Cart from './index';
import { CheckoutProvider } from "../../context/CheckoutContext";
import { CartOpenProvider } from "../../context/CartOpenContext";
import { ApolloProvider } from '@apollo/client'
import ShopifyCachedApolloClient from "../../context/ShopifyCachedApolloClient";

function WithCart(props){

  const client = ShopifyCachedApolloClient();

  // shopify client loads async, default state is loading
  let nullClient = true;

  if( client ){
    nullClient = false;
  }

  // it needs some time bc its loading async from local storage
  // add a null client key to this to let the children know
  const childrenWithProps = React.Children.map(props.children, (child, index) => {
    return React.cloneElement(child, {
      nullClient
    });
  });

  if(client === null && props.children === undefined){
    return (<span className="hidden"></span>);
  }else if( client === null ){
    return childrenWithProps;
  }

  return (<ApolloProvider client={client}>
      <CheckoutProvider>
        <CartOpenProvider>
          <Cart/>
          {childrenWithProps}
        </CartOpenProvider>
      </CheckoutProvider>
    </ApolloProvider>
  );

}

export default WithCart;
