function roundDown(price){
  return (Math.floor(price * 100) / 100).toFixed(2);
}

// wrap helper function for display as line item
export function calculateItemDiscount(discountAllocations, price){
  return roundDown(price - calculateItemDiscountNumber(discountAllocations, price));
}

// https://shopify.dev/api/storefront/2022-04/objects/DiscountAllocation#fields
function calculateItemDiscountNumber(discountAllocations, price){
  let discountAmount = 0;

  discountAllocations.forEach(discountAllocation => {
    const { discountApplication } = discountAllocation;

    if( discountApplication.value.__typename === "MoneyV2" ){
      discountAmount += Number(discountApplication.value.amount);
    }else if( discountApplication.value.__typename === "PricingPercentageValue" ){

      // some other calc for percent
      discountAmount += roundDown(( discountApplication.value.percentage * 0.01 ) * price);
    }
  });

  return discountAmount;
}

export function calculateTotalDiscount(line_items){

  let discountAmount = 0;

  line_items.forEach(item => {
    const line_item = item.node;

    if( line_item.discountAllocations && line_item.discountAllocations.length > 0 ){
      const price = line_item.quantity * line_item.variant.price.amount;
      const itemDiscount = calculateItemDiscountNumber(line_item.discountAllocations, price);
      discountAmount += Number(itemDiscount);
    }
  });

  return discountAmount;
}

export function calculateSubtotal(line_items){

  let total = 0;

  line_items.forEach(item => {
    const line_item = item.node;

    total += Number(line_item.quantity * line_item.variant.price.amount);
  });

  return total;
}

// format price to whole number
export function formatWholePrice(price){
  return `$${Number.parseInt(roundDown(price))}`;
}

export function formatDecimalPrice(price){
  return Number(price).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
}
