import Cookies from 'js-cookie'
import { useState, useEffect } from 'react';
import { createHttpLink, ApolloLink, ApolloClient, InMemoryCache } from '@apollo/client'
import { RetryLink } from "@apollo/client/link/retry";
import { CachePersistor } from 'apollo-cache-persist';
import { InvalidationPolicyCache, RenewalPolicy } from '@nerdwallet/apollo-cache-policies';

const SHOPIFY_CHECKOUT_CLEAR_COOKIE = 'shopifyCheckoutClearCookie'; // this is also in the cart index compnent, iframe view file
const API_VERSION = '2023-07';

export default function ShopifyCachedApolloClient(){
  const [client, setClient] = useState(null);

  useEffect(() => {

    const cache = new InvalidationPolicyCache({
      invalidationPolicies: {
        renewalPolicy: RenewalPolicy.WriteOnly,
        types: {
          // don't specify an expiration for cart
          Shop: {
            timeToLive: 3600 * 1000 // 1hr TTL
          },
        }
      }
    });

    const persistor = new CachePersistor({
      cache,
      storage: window.localStorage,
    });

      const link = ApolloLink.from([
        new RetryLink(),
        new createHttpLink({
          headers: {
            //'X-Shopify-Storefront-Access-Token': 'dd4d4dc146542ba7763305d71d1b3d38'
            'X-Shopify-Storefront-Access-Token': SHOPIFY_KEY,
          },
          uri: `https://${SHOPIFY_CUSTOM_DOMAIN}/api/${API_VERSION}/graphql.json`
        })
      ]);

      persistor.restore().then(()=>{
        const client = new ApolloClient({
          link,
          cache,
        })

        setClient( client );

        // set the on clear store listener
        // triggered from resetToNewCheckout
        client.onClearStore(() => {

          let Raven = window.Raven || {captureException:()=>{}};
          Raven.captureException("Apollo persistance purge, reloading page.");

          // purge the persisted cache as well as
          // the in memory cache
          //
          // https://github.com/apollographql/apollo-cache-persist/issues/446
          // https://github.com/apollographql/apollo-cache-persist/blob/master/docs/advanced-usage.md#using-cachepersistor
          // also don't make any new additions to the cache
          persistor.pause()
          persistor.purge().then(()=>{
            Cookies.remove(SHOPIFY_CHECKOUT_CLEAR_COOKIE);
            window.location.reload();
          })
        })

      })

  }, []);

  return client;
}
