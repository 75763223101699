import React, { useState, useEffect } from 'react';
import { useApolloClient, useMutation, gql } from '@apollo/client'

import {
  useCheckoutEffect,
  createCheckout,
  CheckoutFragment,
} from '../graphql/checkoutQuery';

// shopify custom attributes have to
// have a key key and a value key
export const formatCustomAttributes = (params) => {
  return Object.entries( params ).map(([k,v]) => { return { key : k, value : v } });
};

export const extractCustomAttributes = (attributes) => {

  const attrObj = {};

  attributes.forEach((attribute)=>{
    attrObj[attribute.key] = attribute.value;
  });

  return attrObj;
};

const CheckoutContext = React.createContext([{}, () => {}]);

const CheckoutProvider = (props) => {

  const client = useApolloClient();

  var initialCheckout = { lineItems: { edges: [] }};
  const [checkout,setCheckout] = useState(initialCheckout);

  const [createCheckoutMutation,
  {
    data: createCheckoutData,
    loading: createCheckoutLoading,
    error: createCheckoutError
  }] = useMutation(createCheckout);

  useCheckoutEffect(createCheckoutData, 'checkoutCreate', setCheckout);

  if( createCheckoutError ) throw createCheckoutError;

  const getCachedCheckout  = () => {

    // TODO complain about thid later: https://github.com/apollographql/apollo-client/issues/4724
    const serializedState = client.cache.extract();
    const serializedStateValues = Object.keys(serializedState).map(key => serializedState[key]);
    const cachedCheckouts = serializedStateValues.filter(item => item.__typename === 'Checkout')
      .map(item => {
        const fragRes = client.readFragment({
          fragment: CheckoutFragment,
          fragmentName : 'CheckoutFragment',
          id: `Checkout:${item.id}`,
        })

        //console.log( fragRes );
        return fragRes;

      });

    if( cachedCheckouts.length === 0 ){
      return null;
    }

    // don't assume there is only one in the cache, get the last one
    return cachedCheckouts[cachedCheckouts.length-1];
  };

  // when the component mounts for the first time, see if we need a new checkout
  // if not set the one from the cache
  useEffect(() => {

    // see if there is a checkout in the cache
    // if there isnt we need to create one

    const cachedCheckout = getCachedCheckout();

    if( !cachedCheckout ){
      const variables = { input: {} };
      createCheckoutMutation({ variables }).then(
        res => {
          //console.log( res );
        },
        err => {
          //console.log('create checkout error', err );
        }
      );
    }else{
      setCheckout({...cachedCheckout});
    }
  }, []);

  const resetToNewCheckout = ()=>{

    // this is the nuclear option:
    // clear away and re-get everything
    // at some point in the future if we don't want to reget all queires we can do
    // clearStore()
    // to erase all of the store
    // and only request for specific queries
    // on callback

    // triggers callback in cache persistor
    // in ShopifyCachedApolloClient
    client.clearStore();
  };

  return (
    <CheckoutContext.Provider value={{checkoutState:[checkout, setCheckout], createCheckoutLoading, resetToNewCheckout}}>
      {props.children}
    </CheckoutContext.Provider>
  );
}

export { CheckoutContext, CheckoutProvider };
