import Cookies from 'js-cookie'
import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { calcShipDates } from '../../helpers/calcShipDate.js';

const SHOPIFY_CHECKOUT_CLEAR_COOKIE = 'shopifyCheckoutClearCookie'; // this is also in shopify cached apollo client and the iframe view file

import {
  applyDiscount,
  useCheckoutEffect,
  checkoutCompleteErrorEffect,
  checkoutLineItemsUpdate,
  checkoutLineItemsRemove
} from '../../graphql/checkoutQuery';

import CartPortal from "./CartPortal";
import LineItem from '../LineItem/index';

import { checkoutAnalytics } from '../../helpers/analytics.js';
import { CheckoutContext } from "../../context/CheckoutContext";
import { CartOpenContext } from "../../context/CartOpenContext";
import { calculateSubtotal, calculateTotalDiscount, formatWholePrice, formatDecimalPrice } from "../../helpers/discount";

function Cart(props){

  const { checkoutState, createCheckoutLoading, resetToNewCheckout } = useContext(CheckoutContext);
  const [checkout, setCheckout] = checkoutState;
  const [discountCode, setDiscountCode] = useState('');

  const [isCartOpen, setCartOpen] = useContext(CartOpenContext);

  const [lineItemUpdateMutation,
  {
    data: lineItemUpdateData,
    loading: lineItemUpdateLoading,
    error: lineItemUpdateError
  }] = useMutation(checkoutLineItemsUpdate);

  const [lineItemRemoveMutation,
  {
    data: lineItemRemoveData,
    loading: lineItemRemoveLoading,
    error: lineItemRemoveError
  }] = useMutation(checkoutLineItemsRemove);

  checkoutCompleteErrorEffect( lineItemUpdateError, resetToNewCheckout );
  checkoutCompleteErrorEffect( lineItemRemoveError, resetToNewCheckout );

  useCheckoutEffect(lineItemUpdateData, 'checkoutLineItemsUpdate', setCheckout, resetToNewCheckout);
  useCheckoutEffect(lineItemRemoveData, 'checkoutLineItemsRemove', setCheckout, resetToNewCheckout);

  const updateLineItemInCart = (lineItemId, quantity) => {
    const variables = { checkoutId:checkout.id, lineItems: [{id: lineItemId, quantity: parseInt(quantity, 10)}] };
    lineItemUpdateMutation({ variables });
  };

  const removeLineItemInCart = (lineItemId) => {
    const variables = { checkoutId:checkout.id, lineItemIds: [lineItemId] };
    lineItemRemoveMutation({ variables });
  };

  const [applyDiscountMutation,
  {
    data: applyDiscountData,
    loading: applyDiscountLoading,
    error: applyDiscountError
  }] = useMutation(applyDiscount);

  useCheckoutEffect(applyDiscountData, 'checkoutDiscountCodeApplyV2', setCheckout, resetToNewCheckout);

  const onDiscountApply = () => {
    setDiscountCode('');
    const variables = { checkoutId:checkout.id, discountCode };
    applyDiscountMutation({ variables });
  };


  const waitForCheckoutConfirmCookie = () => {
    // start listening for cookie
    let found = false;
    const ref = setInterval(()=>{
      const clearCookie = Cookies.get(SHOPIFY_CHECKOUT_CLEAR_COOKIE)

      if( clearCookie ){
        // cookie removed before window refresh in clear listener, in apollo cache
        clearTimeout(ref);
        resetToNewCheckout();
      }
    },500)
  };

  const openCheckout = () => {
    window.open(checkout.webUrl);
    waitForCheckoutConfirmCookie();
    checkoutAnalytics(checkout);
  }

  let line_items = checkout.lineItems.edges.map((line_item) => {
    return (
      <LineItem
        removeLineItemInCart={removeLineItemInCart}
        updateLineItemInCart={updateLineItemInCart}
        key={line_item.node.id.toString()}
        line_item={line_item.node}
      />
    );
  });

  const discount = calculateTotalDiscount( checkout.lineItems.edges );

  const subTotal = formatDecimalPrice(calculateSubtotal( checkout.lineItems.edges ));

  const totalQuantity = line_items.length;

  if (createCheckoutLoading) return <div className="Cart"></div>;

  const shipDates = calcShipDates( SHOPIFY_RAILS_SHIP_TIMES, checkout.lineItems.edges );

  const totalPrice = checkout.totalPrice ? formatDecimalPrice(checkout.totalPrice.amount) : 0;

  return (
    <React.Fragment>
      <div className={`Cart ${isCartOpen ? 'Cart--open' : ''}`}>
        <header className="Cart__header">
          <h2>Your Cart</h2>
          <button
            onClick={()=>setCartOpen(false)}
            className="Cart__close">
            ×
          </button>
        </header>
        <div className="Cart__line-items-cont">
          <ul className="Cart__line-items">
            {line_items}
          </ul>
        </div>
        <div className="Cart__footer">
          <div className="Cart-info clearfix">
            <div className="Cart-info__total">SubTotal</div>
            <div className="Cart-info__pricing">
              <span className="total-pricing">{totalPrice}</span>
            </div>
          </div>
          {lineItemUpdateLoading || lineItemRemoveLoading
            ? <button className="Cart__checkout btn btn-primary btn-light" disabled="disabled">CHECKOUT</button>
            : <button className="Cart__checkout btn btn-primary btn-light" onClick={openCheckout}>CHECKOUT</button>
          }
        </div>
      </div>
      <CartPortal
          isCartOpen={isCartOpen}
          setCartOpen={setCartOpen}
          appendId="cart-btn-cont"
          quantity={totalQuantity}
      />
    </React.Fragment>
  );
}

export default Cart;
