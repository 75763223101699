import React from 'react';
import { extractCustomAttributes } from "../../context/CheckoutContext";
import { calculateItemDiscount, formatWholePrice, formatDecimalPrice } from "../../helpers/discount";

function LineItem(props){

  const decrementQuantity = (lineItemId) => {
    const updatedQuantity = props.line_item.quantity - 1
    props.updateLineItemInCart(lineItemId, updatedQuantity);
  }

  const incrementQuantity = (lineItemId) => {
    const updatedQuantity = props.line_item.quantity + 1
    props.updateLineItemInCart(lineItemId, updatedQuantity);
  }

  let chartParams = null;

  if( props.line_item.customAttributes && props.line_item.customAttributes.length > 0 ){
    chartParams = extractCustomAttributes(props.line_item.customAttributes);
  }

  const price = props.line_item.quantity * props.line_item.variant.price.amount;

  let currentPrice = formatWholePrice(price);
  const regularPrice = currentPrice;
  let itemHasSale = false;

  // show price non-integer format if discount
  if( props.line_item.discountAllocations && props.line_item.discountAllocations.length > 0 ){
    // show discount amount
    currentPrice = formatDecimalPrice(calculateItemDiscount(props.line_item.discountAllocations, price));
    itemHasSale = true
  }

  return (
    <li className="Line-item">
      {chartParams &&
      <div className="Line-item__img">
        {props.line_item.variant.image ?
          <div
            className="nested-item-cont"
            style={{ backgroundImage: 'url(' + props.line_item.variant.image.src + ')'}}
          ></div>
          : null
        }
      </div>
      }
      <div className="Line-item__content w-100">
        <div className="Line-item__content-row">
          {chartParams &&
          <div className="Line-item__chart">
            {chartParams.formatted_date} at {chartParams.formatted_place}
          </div>
          }
          <span className="Line-item__title">
            <span className="Line-item__variant-title">{props.line_item.variant.title}</span> - <span className="Line-item__full-title">{props.line_item.title.toLowerCase()}</span>
          </span>
        </div>
        <div className="Line-item__content-row price-qty">
          <div className="Line-item__quantity-container">
            <span className="Line-item__quantity-update" onClick={() => decrementQuantity(props.line_item.id)}>-</span>
            <span className="Line-item__quantity">{props.line_item.quantity}</span>
            <span className="Line-item__quantity-update" onClick={() => incrementQuantity(props.line_item.id)}>+</span>
          </div>

          <span className="Line-item__price">
            {itemHasSale &&
            <span className="Line-item__regular-price regular-price">
            { regularPrice }
            </span>
            }
            <span className={`Line-item__current-price ${itemHasSale === true? 'sale-price' : ''}`}>
            { currentPrice }
            </span>
          </span>
          <button className="Line-item__remove" onClick={()=> props.removeLineItemInCart(props.line_item.id)}>×</button>
        </div>
      </div>
    </li>
  );
}

export default LineItem;
