import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import WithCart from "../Cart/WithCart";

import CartPageErrorBoundary from "./CartPageErrorBoundary";

function CartPageWithErrorBoundary(props){

  return (<CartPageErrorBoundary>
    <WithCart {...props}>
    </WithCart>
  </CartPageErrorBoundary>)
}

export default CartPageWithErrorBoundary;
