import { gql } from "@apollo/client";
import { useEffect } from "react";

export const CheckoutFragment = gql`
  fragment CheckoutFragment on Checkout {
    id
    webUrl
    totalTax {
      ... on MoneyV2 {
        amount
        currencyCode
      }
    }

    subtotalPrice {
      ... on MoneyV2 {
        amount
        currencyCode
      }
    }
    totalPrice {
      ... on MoneyV2 {
        amount
        currencyCode
      }
    }
    shippingDiscountAllocations {
      discountApplication {
        allocationMethod
        targetSelection
        targetType
        value {
          __typename
          ... on PricingPercentageValue {
            percentage
          }
          ... on MoneyV2 {
            amount
            currencyCode
          }
        }
      }
      allocatedAmount {
        amount
        currencyCode
      }
    }
    discountApplications(first: 250) {
      edges {
        node {
          allocationMethod
          targetSelection
          targetType
          value {
            __typename
            ... on PricingPercentageValue {
              percentage
            }
            ... on MoneyV2 {
              amount
              currencyCode
            }
          }
        }
      }
    }
    lineItems(first: 250) {
      edges {
        node {
          id
          title
          discountAllocations {
            discountApplication {
              allocationMethod
              targetSelection
              targetType
              value {
                __typename
                ... on PricingPercentageValue {
                  percentage
                }
                ... on MoneyV2 {
                  amount
                  currencyCode
                }
              }
            }
            allocatedAmount {
              amount
              currencyCode
            }
          }
          variant {
            id
            title
            image {
              src
            }
            price {
              ... on MoneyV2 {
                amount
                currencyCode
              }
            }
          }
          quantity
          customAttributes {
            key
            value
          }
        }
      }
    }
  }
`;

export const applyDiscount = gql`
  mutation checkoutDiscountCodeApplyV2(
    $checkoutId: ID!
    $discountCode: String!
  ) {
    checkoutDiscountCodeApplyV2(
      checkoutId: $checkoutId
      discountCode: $discountCode
    ) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        code
        message
        field
      }
    }
  }
  ${CheckoutFragment}
`;

export const createCheckout = gql`
  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      userErrors {
        message
        field
      }
      checkoutUserErrors {
        code
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export const checkoutLineItemsAdd = gql`
  mutation checkoutLineItemsAdd(
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemInput!]!
  ) {
    checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
      userErrors {
        message
        field
      }
      checkoutUserErrors {
        code
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export const checkoutLineItemsUpdate = gql`
  mutation checkoutLineItemsUpdate(
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemUpdateInput!]!
  ) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      userErrors {
        message
        field
      }
      checkoutUserErrors {
        code
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export const checkoutLineItemsRemove = gql`
  mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(
      checkoutId: $checkoutId
      lineItemIds: $lineItemIds
    ) {
      userErrors {
        message
        field
      }
      checkoutUserErrors {
        code
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export const checkoutCustomerAssociate = gql`
  mutation checkoutCustomerAssociate(
    $checkoutId: ID!
    $customerAccessToken: String!
  ) {
    checkoutCustomerAssociate(
      checkoutId: $checkoutId
      customerAccessToken: $customerAccessToken
    ) {
      userErrors {
        field
        message
      }
      checkoutUserErrors {
        code
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export function useCheckoutEffect(
  data,
  key,
  setDataCallback,
  resetToNewCheckout
) {
  useEffect(() => {
    if (data && data[key]) {
      if (data[key]["userErrors"] && data[key]["userErrors"].length > 0) {
        let Raven = window.Raven || { captureException: () => {} };
        Raven.captureException(data);

        // TODO: does this error check need to
        // be more granular?
        resetToNewCheckout();
      } else if (data[key].checkout) {
        setDataCallback(data[key].checkout);
      }
    }
  }, [data]);
}

export function checkMutationError(data, key) {
  if (data && data[key]) {
    if (data[key].userErrors.length > 0) {
      return true;
    }
  }

  return false;
}

// make a generic way to listen for the checkout complete error
// we'll call the apollo client checkout reset
export function checkoutCompleteErrorEffect(error, errorCallback) {
  useEffect(() => {
    if (error && error.message) {
      if (error.message === "Checkout is already completed.") {
        errorCallback();
      } else {
        let Raven = window.Raven || { captureException: () => {} };
        Raven.captureException(error);

        throw error;
      }
    }
  }, [error]);
}
