import React from 'react';

import ErrorMessage from '../ErrorMessage/index';
class CartPageErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch(error, errorInfo) {

    // Catch errors in any child components and re-renders with an error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });

    let Raven = window.Raven || {captureException:()=>{}};
    Raven.captureException(error);
  }

  render() {
    if (this.state.error) {

      console.log(this.state.error.toString());
      console.log(this.state.errorInfo.componentStack);

      // Fallback UI if an error occurs
      return (<ErrorMessage/>);
    }else{
      // component normally just renders children
      return this.props.children;
    }
  }
}

export default CartPageErrorBoundary;
