// https://developers.google.com/analytics/devguides/collection/ga4/reference/events
export function checkoutAnalytics (checkout) {
  try{
    //use the cart global
    const ttq = window.ttq || (window.ttq = function(){});
    ttq.track || (ttq.track = function(){});
    ttq.track('InitiateCheckout')

  }catch(e){
    let Raven = window.Raven || {captureException:()=>{},captureMessage: ()=>{}};
    Raven.captureException(e);
    Raven.captureMessage('tt checkout analytics')
  }

  try{
    //use the cart global
    const fbq = window.fbq || (window.fbq = function(){});

    const fb_items = checkout.lineItems.edges.map(item => {
      let line_item = item.node;
      return {
        id:line_item.variant.id,
        price:line_item.variant.price.amount,
        quantity:line_item.quantity
      };
    });

    const variant_ids = checkout.lineItems.edges.map(item => {
      return item.node.variant.id;
    });

    //https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.10#events
    fbq('track', 'InitiateCheckout', {
      value: checkout.subtotalPrice.amount,
      currency: 'USD',
      content_ids:variant_ids,
      num_items:checkout.lineItems.edges.length,
      contents: fb_items
    });

  }catch(e){
    let Raven = window.Raven || {captureException:()=>{},captureMessage: ()=>{}};
    Raven.captureException(e);
    Raven.captureMessage('fb checkout analytics')
  }

  try{

    const google_items = checkout.lineItems.edges.map(item => {
      let line_item = item.node;
      return {
        'item_name': line_item.variant.title,
        'item_id': line_item.variant.id,
        'price': line_item.variant.price.amount,
        'quantity':line_item.quantity
      };
    });

    const dataLayer = window.dataLayer || (window.dataLayer = function(){});
    function gtag(){window.dataLayer.push(arguments);}

    gtag("event", "begin_checkout", {
      currency: "USD",
      value: checkout.subtotalPrice.amount,
      items: google_items,
    });

  }catch(e){
    let Raven = window.Raven || {captureException:()=>{},captureMessage: ()=>{}};
    Raven.captureException(e);
    Raven.captureMessage('ga checkout analytics')
  }
};

export function addToCartAnalytics(variantId, price, quantity, name) {

  try{

    const ttq = window.ttq || (window.ttq = function(){});
    ttq.track || (ttq.track = function(){});

    ttq.track('AddToCart',{
        content_id: variantId,
        quantity: quantity,
        price: price,
        value: price,
        currency: 'USD'
    });

  }catch(e){
    let Raven = window.Raven || {captureException:()=>{},captureMessage: ()=>{}};
    Raven.captureException(e);
    Raven.captureMessage('tt checkout analytics')
  }


  try{
    const fbq = window.fbq || (window.fbq = function(){});

    //https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.10#events
    fbq('track', 'AddToCart', {
      value: price * quantity,
      currency: 'USD',
      contents: [
        {
          id:variantId,
          price:price,
          quantity:quantity
        }
      ]
    });
  }catch(e){
    let Raven = window.Raven || {captureException:()=>{},captureMessage: ()=>{}};
    Raven.captureException(e);
    Raven.captureMessage('fb atc analytics')
  }

  try{

    const dataLayer = window.dataLayer || (window.dataLayer = function(){});
    function gtag(){window.dataLayer.push(arguments);}

    gtag("event", "add_to_cart", {
      currency: "USD",
      value: price * quantity,
      items: [
        {
          item_id: variantId,
          item_name: name,
          currency: "USD",
          price: price,
          quantity: quantity
        }
      ]
    });

  }catch(e){
    let Raven = window.Raven || {captureException:()=>{},captureMessage: ()=>{}};
    Raven.captureException(e);
    Raven.captureMessage('ga atc analytics')
  }
};

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events
export function promoAnalytics(name) {
  try{
    //use the cart global
    const fbq = window.fbq || (window.fbq = function(){});
    //https://developers.facebook.com/docs/meta-pixel/reference
    fbq('track', 'CompleteRegistration', {
      content_name:name
    });

    const dataLayer = window.dataLayer || (window.dataLayer = function(){});
    function gtag(){window.dataLayer.push(arguments);}

    gtag("event", "select_promotion", {
      creative_name: name,
    });
  }catch(e){

    let Raven = window.Raven || {captureException:()=>{},captureMessage: ()=>{}};
    Raven.captureException(e);
    Raven.captureMessage('promo analytics')
  }
}

// define it globally so that it can be used wherever
window.globalPromoAnalytics = promoAnalytics;
