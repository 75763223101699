import { createPortal } from "react-dom";
import React, { useState, useEffect } from 'react';

const CartPortal = (props) => {
  const portalEl = document.createElement('div');
  //portalEl.classList.add('d-flex');
  const [modalContainer] = useState(portalEl);

  useEffect(() => {
    // Find the root element in your DOM
    let modalRoot = document.getElementById(props.appendId);

    // Append modal container to root
    modalRoot.appendChild(modalContainer);
    return function cleanup() {
      // On cleanup remove the modal container
      modalRoot.removeChild(modalContainer);
    };
  }, []); // <- The empty array tells react to apply the effect on mount/unmount

  let quantity = props.quantity > 0 ? props.quantity : '';

  const cart = quantity > 0 ? (<button
        className="btn btn--cart-tab js-prevent-cart-listener"
        onClick={()=> props.setCartOpen(!props.isCartOpen)}
      >
      <div className="btn-counter-cont">
        <div className="counter-text-cont">
          <span id="cart-counter" className="btn__counter">{quantity}</span>
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" className="icon-cart icon-cart--side" viewBox="0 0 25 25" enable-background="new 0 0 25 25"><g fill="#525252"><path d="M24.6 3.6c-.3-.4-.8-.6-1.3-.6h-18.4l-.1-.5c-.3-1.5-1.7-1.5-2.5-1.5h-1.3c-.6 0-1 .4-1 1s.4 1 1 1h1.8l3 13.6c.2 1.2 1.3 2.4 2.5 2.4h12.7c.6 0 1-.4 1-1s-.4-1-1-1h-12.7c-.2 0-.5-.4-.6-.8l-.2-1.2h12.6c1.3 0 2.3-1.4 2.5-2.4l2.4-7.4v-.2c.1-.5-.1-1-.4-1.4zm-4 8.5v.2c-.1.3-.4.8-.5.8h-13l-1.8-8.1h17.6l-2.3 7.1z"></path><circle cx="9" cy="22" r="2"></circle><circle cx="19" cy="22" r="2"></circle></g></svg>
      </button>) : (<></>);

  return createPortal(cart, modalContainer);
};

export default CartPortal;




